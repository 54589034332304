import React from 'react';
import { Link, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { Container, DropDownList, DropDownLink } from './SolutionsDropdown';
import { COLORS } from '../../ui/variables';

const MenuContainer = styled(Container)<{ isActive: boolean }>`
  justify-content: center;
  gap: 150px;
`;

const RegionLink = styled(Link)`
  color: ${COLORS.DARK_GRAY};
`;

interface Props {
  display: boolean;
}

export interface LocationNode {
  locationName: string;
  path: string;
  locationRegion: string;
}

export interface GroupedLocation {
  slug: string;
  displayName: string;
  locations: {
    title: string;
    path: string;
  }[];
}

const LocationsDropdown = (props: Props) => {
  const locationsData = useStaticQuery(graphql`
    query {
      allContentfulPagesLocationTemplate(
        filter: {
          locationRegion: { in: ["boston", "new-york-city", "washington-dc"] }
          ghostLocation: { eq: false }
        }
      ) {
        nodes {
          locationName
          path
          locationRegion
        }
      }
    }
  `);

  const groupedLocations: GroupedLocation = locationsData.allContentfulPagesLocationTemplate.nodes.reduce(
    (acc, location: LocationNode) => {
      const { locationRegion, locationName, path } = location;

      if (!acc[locationRegion]) {
        acc[locationRegion] = {
          slug: locationRegion,
          displayName:
            locationRegion === 'washington-dc'
              ? 'Washington DC Area'
              : locationRegion.replace(/-/g, ' '),
          locations: [],
        };
      }

      acc[locationRegion].locations.push({
        title: locationName,
        path,
      });

      return acc;
    },
    {}
  );

  // Sort regions & locations alphabetically
  const sortedRegions = Object.values(groupedLocations).sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );
  sortedRegions.forEach(region => {
    region.locations.sort((a, b) => a.title.localeCompare(b.title));
  });

  return (
    <MenuContainer isActive={props.display}>
      {sortedRegions.map(region => (
        <DropDownList key={region.slug}>
          <li>
            <RegionLink to={`/locations/${region.slug}`}>
              {region.displayName}
            </RegionLink>
          </li>
          {region.locations.map((location: { path: string; title: string }) => (
            <li key={location.path}>
              <DropDownLink to={location.path}>{location.title}</DropDownLink>
            </li>
          ))}
        </DropDownList>
      ))}
    </MenuContainer>
  );
};

export default LocationsDropdown;
