import React, { useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { COLORS } from '../../ui/variables';
import ArrowRightIco from '../../images/icons/arrow-right.svg';

const InputWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 320px;
`;

const SubscribeInput = styled.input`
  height: 56px;
  color: ${COLORS.BLACK};
  background: ${COLORS.WHITE};
  border: 2px solid ${COLORS.BLACK};
  padding: 0 16px;
  line-height: 56px;
  outline: none;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;

  &::placeholder {
    color: ${COLORS.DARK_GRAY};
  }
`;

const SubscribeSubmit = styled.button`
  position: absolute;
  right: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 56px;
  height: 56px;
  border: 0;
  background: ${COLORS.BLACK};
  background-image: url(${ArrowRightIco});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  cursor: pointer;
`;

const Success = styled.div`
  border-radius: 4px;
  background-color: #c7e6c7;
  color: #5cb85c;
  padding: 6px 12px;
`;

const NewsletterSubscribe = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');

  const onSubmit = async () => {
    if (loading || email.length === 0) {
      return;
    }

    if (!email.match(/^\S+@\S+\.\S+$/)) {
      return setError(true);
    }

    setLoading(true);
    setError(false);
    const resp = await fetch(
      `${process.env.GATSBY_SMALL_DOOR_APP_BASE_URL}/api/v1/nl/signup`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      }
    );
    setLoading(false);

    if (!resp.ok) {
      window.alert('There was an error, please try again.');
      return;
    }

    setSuccess(true);
    setEmail('');
  };

  if (success) {
    return <Success>Thanks for subscribing!</Success>;
  }

  return (
    <InputWrap>
      <SubscribeInput
        css={css`
          border-color: ${error ? '#d9534f' : COLORS.BLACK};
        `}
        value={email}
        onChange={e => setEmail(e.target.value)}
        placeholder='Enter your email'
        disabled={loading}
      />
      <SubscribeSubmit aria-label='Submit email' onClick={onSubmit} />
    </InputWrap>
  );
};

export default NewsletterSubscribe;
