// Title tiny

import styled from '@emotion/styled';
import { COLORS, BREAKPOINTS } from './variables';

const Heading4 = styled.h4`
  color: ${COLORS.BLACK};
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 20px;
    line-height: 24px;
  }
`;

export default Heading4;
