import React from 'react';
import GlobalStyles from './GlobalStyles';

import Header from './header/Header';
import Footer from './Footer';
import './layout.css';

interface Props {
  children?: React.ReactNode;
  promo?: string;
  stickyHeader?: boolean;
}

const Layout = ({ promo = '0', children, stickyHeader }: Props) => {
  return (
    <div>
      <GlobalStyles />

      <Header sticky={stickyHeader} />

      <main>{children}</main>

      <Footer />
    </div>
  );
};

export default Layout;
